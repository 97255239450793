"use client";

import React, { useEffect, useRef } from "react";
import { Fragment, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import Link from "next/link";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  BarChartCircle01,
  BarLineChart,
  NotificationBox,
  Settings02,
  Users01,
  SearchSm,
} from "@untitled-ui/icons-react";
import { useUser } from "@auth0/nextjs-auth0/client";
import {
  Menu,
  MenuItems,
  MenuItem,
  MenuButton,
  Transition as MenuTransition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "./Sidebar";

import { useGetUserProfile } from "@/app/hooks/userHook";
import { useSettingsStore } from "@/app/stores/settingsStore";
import { useClientStore } from "@/app/stores/clientStore";

import { US, DE } from "country-flag-icons/react/3x2";
import * as m from "@/paraglide/messages";
import { setLanguageTag } from "@/paraglide/runtime";
import * as Sentry from "@sentry/nextjs";
import { NotificationMenu } from "../NotificationMenu/NotificationMenu";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import LanguageSelector from "./languageSelector";
import Image from "next/image";

type Breadcrumb = {
  name: string;
  href: string;
  isCurrent: boolean;
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const AuthLayoutMenu = () => {
  const { user, isLoading: isUserLoading, error: userError } = useUser();
  const { settings, selectSettings, setLanguage } = useSettingsStore();
  const {
    data: userProfile,
    isLoading: isProfileLoading,
    error: profileError,
  } = useGetUserProfile({
    userId: user?.sub?.toString() || "",
    organizationId: user?.org_id || "",
    enabled: true,
  });

  const [isFullMenuOpen, setIsFullMenuOpen] = useState(false);
  const pathname = usePathname();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [buttonView, setButtonView] = useState(
    <US title="en" className="w-5 h-5" />
  );
  
  const clientStore = useClientStore();
  const {getActiveMonitorLink} = clientStore;
  const activeMonitorLink = getActiveMonitorLink();


  // This should be called regardless of loading or error states
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user?.email || "",
        given_name: user?.given_name,
        family_name: user?.family_name,
        nickname: user?.nickname,
      });

      Sentry.setContext("organization", {
        org_name: user.display_name,
        org_id: user.org_id,
      });
    }
  }, [user]);

  useEffect(() => {
    switch (settings.language) {
      case "en":
        setButtonView(<US title="en" className="w-5 h-5" />);
        break;
      case "de":
        setButtonView(<DE title="de" className="w-5 h-5" />);
        break;
      default:
        setButtonView(<US title="en" className="w-5 h-5" />); // Default case, though it should never be reached
    }
  }, [settings.language]);

  const pathChunks = pathname.split("/").filter(Boolean);
  // Exclude specific segments such as language codes
  const filteredPathChunks = pathChunks.filter(
    (chunk) => chunk !== "en" && chunk !== "de"
  );

  const breadcrumbPaths = filteredPathChunks.map((chunk, index) => {
    const name = (activeMonitorLink?.company && activeMonitorLink?.id === chunk) ? activeMonitorLink.company.name : (chunk.charAt(0).toUpperCase() + chunk.slice(1)).replace(/([A-Z])/g, " $1").trim();
    return {
      name,
      href: "/" + filteredPathChunks.slice(0, index + 1).join("/"), // Create path for each breadcrumb
      isCurrent: index === filteredPathChunks.length - 1, // Last item is the current page
    };
  });
  const breadcrumbs = [...breadcrumbPaths];
  

  const isLoading = isUserLoading || isProfileLoading;
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingAnimation />
      </div>
    );
  }

  // Check administrative privileges for the admin panel in the menu bar
  function hasSuperAdminPermission(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          (key === "source_name" && obj[key] === "SuperAdmin") ||
          obj[key] === "Admin"
        ) {
          return true;
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          if (hasSuperAdminPermission(obj[key])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const hasAdminPermission = hasSuperAdminPermission(userProfile);

  const navigation = [
    {
      name: m.nav_home(),
      href: `/${settings.language}/home`,
      icon: BarChartCircle01,
      current: pathname.toString().includes("/home"),
    },
    {
      name: m.nav_client_management(),
      href: `/${settings.language}/accountManagement`,
      icon: Users01,
      current: pathname.toString().includes("/accountManagement"),
    },
    // {
    //   name: m.nav_notifications(),
    //   href: `/${settings.language}/notifications`,
    //   icon: NotificationBox,
    //   current: pathname.toString().includes("/notifications"),
    // },
    // {
    //   name: "Document Upload",
    //   href: `/${settings.language}/documentUpload`,
    //   icon: SearchSm,
    //   current: pathname.toString().includes("/documentUpload"),
    // },
    //{
    //  name: m.nav_admin_panel(),
    //  href: `/${settings.language}/adminPanel/`,
    //  icon: Settings02,
    //  current: pathname.toString().includes("/adminPanel"),
    //},

    hasAdminPermission
      ? {
          name: m.nav_admin_panel(),
          href: `/${settings.language}/adminPanel/`,
          icon: Settings02,
          current: pathname.toString().includes("/adminPanel"),
        }
      : null,
  ].filter(Boolean);

  const userDisplayName =
    userProfile?.given_name && userProfile?.family_name
      ? `${userProfile?.given_name} ${userProfile?.family_name}`
      : userProfile?.given_name
        ? userProfile.given_name
        : user?.name || user?.nickname;
  return (
    <>
      {/* Full AuthLayoutMenu container */}
      <div>
        {/* Top Button to toggle full-screen menu */}
        <div className="lg:hidden">
          <button
            type="button"
            className="bg-gray-900 text-white p-2 absolute top-2 right-2 rounded-md"
            onClick={() => setIsFullMenuOpen(!isFullMenuOpen)}
          >
            {isFullMenuOpen ? "Collapse" : "Expand"}
          </button>
        </div>
        {/* Sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative  lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center"></div>
                    <nav
                      id="responsiveSidebar"
                      className="flex flex-1 flex-col"
                    >
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item?.name}>
                                {/* Check if 'item' is not null before accessing its properties */}
                                {item ? (
                                  // Use Link component for internal links
                                  item.href.startsWith("/") ? (
                                    <Link href={item.href}>
                                      <span
                                        className={classNames(
                                          item?.current
                                            ? "bg-gray-50 text-indigo-600"
                                            : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        )}
                                      >
                                        <item.icon
                                          className={classNames(
                                            item?.current
                                              ? "text-indigo-600"
                                              : "text-gray-400 group-hover:text-indigo-600",
                                            "h-6 w-6 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </span>
                                    </Link>
                                  ) : (
                                    // Render non-internal links as regular anchor tags
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        item?.current
                                          ? "bg-gray-50 text-indigo-600"
                                          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      )}
                                    >
                                      <item.icon
                                        className={classNames(
                                          item?.current
                                            ? "text-indigo-600"
                                            : "text-gray-400 group-hover:text-indigo-600",
                                          "h-6 w-6 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </a>
                                  )
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <Sidebar navigation={navigation} setSidebarOpen={setSidebarOpen} />

        <main
          className={`pt-5 bg-white border-b border-gray-100 shadow-sm ${
            settings.isSidebarOpen ? "lg:pl-72" : "pl-16"
          }`}
        >
          <div className="px-4 sm:px-6 lg:px-8 ">
            {/* Sticky top-bar area */}

            <div className="flex justify-between ">
              {/* Breadcrumbs */}

              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <div className="flex justify-end">
                {/* Profile dropdown */}

                {/*<NotificationMenu />*/}
                <Menu
                  as="div"
                  className="relative inline-block text-left pr-5 pb-3"
                >
                  <div>
                    <MenuButton>
                      <a href="#" className="group block flex-shrink-0">
                        <div className="flex items-center">
                          <Image
                            src={(user?.picture as string) || ""}
                            className="rounded-full object-cover"
                            alt="User Avatar"
                            height={36}
                            width={36}
                          />
                          <div className="text-sm px-2 text-left">
                            <div>{userDisplayName}</div>
                            <div>{userProfile?.email || user?.email}</div>
                          </div>
                        </div>
                      </a>
                    </MenuButton>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="z-10 absolute right-0  mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem>
                        {({ active }) => (
                          <Link href={"/user"}>
                            <span
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Account settings
                            </span>
                          </Link>
                        )}
                      </MenuItem>

                      <form method="POST" action="#">
                        <MenuItem>
                          {({ active }) => (
                            <a
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                              href="/api/auth/logout"
                            >
                              Sign out
                            </a>
                          )}
                        </MenuItem>
                      </form>
                    </MenuItems>
                  </Transition>
                </Menu>
                <LanguageSelector />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default React.memo(AuthLayoutMenu);
