import React, { useState } from "react";
import { Bars3Icon, LightBulbIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Image from "next/image";
import { ChevronRight } from "@untitled-ui/icons-react";
import { Tooltip } from "react-tooltip";
import { useSettingsStore } from "@/app/stores/settingsStore";

// Sidebar.js
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({
  navigation,
  setSidebarOpen,
}: {
  navigation: any;
  setSidebarOpen: any;
}) => {
  const { settings, selectSettings } = useSettingsStore();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [width, setWidth] = useState("72");
  const [logo, setLogo] = useState({
    src: "/assets/branding/creditPulse_title.png", // Updated path
    height: 30,
    width: 250,
  });

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    const newLogo = isCollapsed
      ? { src: "/assets/branding/creditpulse_logo.png", height: 30, width: 30 } // Updated path
      : {
          src: "/assets/branding/creditPulse_title.png",
          height: 30,
          width: 250,
        }; // Updated path
    const newWidth = isCollapsed ? "10" : "72";

    setLogo(newLogo);
    setWidth(newWidth);

    // Toggle the isSidebarOpen value
    const newSettings = {
      ...settings,
      isSidebarOpen: !settings.isSidebarOpen,
    };

    // Update the Zustand store with the new settings
    selectSettings(newSettings);
  };

  return (
    <>
      <div>
        <div
          className={`hidden lg:fixed lg:inset-y-0 lg:z-20 lg:flex lg:w-${width} lg:flex-col`}
        >
          <Tooltip id="navigation-menu" />
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <span className="flex flex-row-reverse">
              <button
                onClick={toggleSidebar}
                className="absolute right-0 top-0 mt-4 mr-4"
              >
                {isCollapsed ? (
                  <ChevronRight className="position-relative" />
                ) : (
                  <ChevronRight className="position-relative transform rotate-180" />
                )}
              </button>
            </span>
            <div className="flex h-16 shrink-0 items-center">
              <div className="flex items-center">
                <Image
                  className="mt-4"
                  src={logo.src}
                  alt="Main Credit Pulse Logo"
                  width={logo.width}
                  height={logo.height}
                />
              </div>
            </div>

            <div className="flex grow flex-col justify-between">
              <nav className="flex flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item: any) => (
                        <li key={item.name}>
                          <Link href={item.href}>
                            <span
                              className={classNames(
                                item.current
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                data-tooltip-id={
                                  isCollapsed ? undefined : "navigation-menu"
                                } // Conditionally set data-tooltip-id
                                data-tooltip-content={
                                  isCollapsed ? undefined : item.name
                                } // Conditionally set data-tooltip-content
                                data-tooltip-place={
                                  isCollapsed ? undefined : "top"
                                } // Conditionally set data-tooltip-place
                                className={classNames(
                                  item.current
                                    ? "text-indigo-600"
                                    : "text-gray-400 group-hover:text-indigo-600 ",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {isCollapsed ? item.name : null}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>

              {settings.isSidebarOpen ? (
                <div className="text-center p-4">
                  <a
                    className="text-swyft-secondary-button text-sm"
                    href={process.env.NEXT_PUBLIC_SERVICE_DESK_PORTAL_URL}
                    target="_blank"
                  >
                    <div>Report A Bug</div> <div>or</div>{" "}
                    <div>Make A Suggestion</div>
                  </a>
                </div>
              ) : (
                <span
                  className="pb-5"
                  data-tooltip-id="navigation-menu"
                  data-tooltip-content="Report A Bug or Make A Suggestion"
                  data-tooltip-place="top"
                >
                  <a
                    href={process.env.NEXT_PUBLIC_SERVICE_DESK_PORTAL_URL}
                    target="_blank"
                  >
                    <LightBulbIcon className="h-6 w-6 shrink-0 p-0 m-0 text-gray-400 group-hover:text-indigo-600" />
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="sticky top-0 z-20 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden ">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden "
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900 ">
            Home
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
