"use client";

import { DE, US } from "country-flag-icons/react/3x2";
import { setLanguageTag } from "@/paraglide/runtime";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { useState } from "react";
import { useSettingsStore } from "@/app/stores/settingsStore";

const LanguageSelector = () => {
  const [locale, setLocale] = useState<"en" | "de">("en");
  const [buttonView, setButtonView] = useState(
    <US title="en" className="w-5 h-5" />
  );

  const handleMenuChoiceChange = (choice: string) => {
    if (choice === "en") {
      setLocale(choice);
      setLanguage(choice);
      setLanguageTag(choice); // Set the language tag to the chosen language
    } else {
      console.error("Unsupported locale:", choice);
    }
  };

  const { setLanguage } = useSettingsStore();

  return (
    <DropdownMenu
      className="pl-10 ml-10"
      menuItems={[
        {
          name: "en",
          value: "en",
          icon: <US title="en" className="w-5 h-5" />,
        },
      ]}
      selectedItem={locale}
      selectedItemView='icon'
      onMenuChoiceChange={handleMenuChoiceChange}
    ></DropdownMenu>
  );
};

export default LanguageSelector;
