import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/client/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/next/font/google/target.css?{\"path\":\"src/app/(routes)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/client/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/components/layoutMenu/LayoutMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientLanguageProvider"] */ "/vercel/path0/client/src/app/lib/providers/ClientLanguageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/lib/providers/providers.tsx");
