/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * @param {{ languageTag: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const currentLanguageTag = (params) => `Der aktuelle Sprachtag ist "${params.languageTag}".`


/**
 * @param {{ name: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const greeting = (params) => `Hallo ${params.name}! Du hast ${params.count} Nachrichten.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nav_home = () => `Armaturenbrett`


export { nav_credit_risk_monitoring } from "./en.js"

export { nav_client_management } from "./en.js"

export { nav_client_search } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nav_accounts = () => `Konten`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nav_checklist = () => `Checkliste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nav_notifications = () => `Benachrichtigungen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nav_integrations = () => `Integrationen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nav_admin_panel = () => `Verwaltung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nav_profile = () => `Profil anzeigen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const checklist_title = () => `Buchhaltungs-Checkliste für Ihre Organisation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const checklist_subtitle = () => `Verwenden Sie diese Checkliste, um sicherzustellen, dass Sie alle wichtigen Schritte für die Buchhaltung Ihrer Organisation durchführen.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const checklist_stat_panel_1 = () => `Herausragende Aufgaben`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const checklist_stat_panel_2 = () => `Überfällig`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const checklist_stat_panel_3 = () => `Diesen Monat erledigt`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const checklist_stat_panel_4 = () => `Dieses Jahr erledigt`
